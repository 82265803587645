<template>
  <div class="d-flex flex-row">
    <v-combobox
      class="search-input"
      clearable
      filled
      solo
      dense
      v-model="selected"
      ref="autocomplete"
      :items="getItems"
      :search-input.sync="search"
      no-filter
      :label="
        isListening ? $t('navbar.speechActive') : $t('navbar.searchPlaceholder')
      "
      :placeholder="
        isListening ? $t('navbar.speechActive') : $t('navbar.searchPlaceholder')
      "
      flat
      item-text="name"
      item-value="name"
      hide-details
      hide-no-data
      return-object
      type="search"
      @focus="onFocus"
      @blur="onBlur"
      @keydown.enter.native.prevent.stop="doSearch"
      :menu-props="{
        closeOnContentClick: true,
        contentClass: 'searches-menu'
      }"
    >
      <template v-slot:item="{ item }">
        <div class="d-flex w-100" :class="item.type">
          <!-- <v-list-item-icon
            v-if="item.type == 'Categorie'"
            class="category-img"
          >
            <img :src="getImage(item)" onerror="this.src='/no-icon.png'" />
          </v-list-item-icon> -->
          <v-list-item-icon v-if="item.type == 'Ricerche'">
            <v-icon @click.prevent.stop="deleteWord(item)" small>
              $close
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content @click="doSearch(item)">
            <v-list-item-title>
              <text-highlight :queries="search ? search : '*'">
                {{ item.name }}
              </text-highlight>
            </v-list-item-title>
            <v-list-item-subtitle v-if="item.type == 'Categorie'">
              <v-badge
                inline
                v-if="item.count"
                :content="item.count"
                color="secondary"
                class="rounded-badge"
              >
                Cerca <strong> {{ search }} </strong> in {{ item.name }}
              </v-badge>
            </v-list-item-subtitle>
            <v-list-item-subtitle v-if="item.type == 'Marche'">
              Cerca <strong> {{ search }} </strong> con marca {{ item.name }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </div>
      </template>
    </v-combobox>
    <Barcode v-if="isCordova" @input="doSearch" />
  </div>
</template>
<style lang="scss">
.search-input {
  border: 1px solid #e1e1e1;
  border-radius: 6px;
  opacity: 1;
  font-size: 13px;
  .v-input__control {
    display: inline;
    height: 100%;
    .v-input__slot {
      height: 100%;
    }
    .v-select__slot {
      input::placeholder {
        font-size: 11px !important;
      }
    }
  }
  .v-label {
    font-size: 11px !important;
  }
}
.searches-menu {
  max-height: 80vh !important;
  background-color: blue;
  &.v-list--dense {
    .v-list-item {
      min-height: 32px;
    }
  }
  .v-list-item {
    .v-list-item__content {
      padding: 2px 0;
    }
    .v-list-item__title {
      font-size: 1rem;
    }
    .link {
      .v-list-item__title {
        color: var(--v-primary-base);
        font-weight: bold;
        font-size: 16px;
      }
    }
  }
  .v-subheader {
    font: normal normal bold 14px/44px Montserrat;
    white-space: nowrap;
    &::before {
      content: "";
      display: block;
      width: 30px;
      height: 2px;
      background: var(--v-primary-base);
      margin-right: 8px;
    }
    &::after {
      content: "";
      display: block;
      width: 80%;
      height: 2px;
      background: var(--v-primary-base);
      margin-left: 8px;
    }
  }
}
</style>
<script>
import Barcode from "./Barcode.vue";
import FastSearchMenu from "./FastSearchMenu.vue";

import ProductService from "~/service/productService";
import AnalyticsService from "~/service/analyticsService";

import { mapGetters, mapActions } from "vuex";

import debounce from "lodash/debounce";
import forEach from "lodash/forEach";

import LongPress from "vue-directive-long-press";
import cmService from "@/commons/service/cmService";

export default {
  components: {
    Barcode
  },
  data() {
    return {
      loading: false,
      isFocused: null,
      selected: null,
      search: null,
      isListening: false,
      items: [],
      menuProps: {
        closeOnContentClick: true,
        openOnClick: true
      },
      speechOptions: {
        language: "it-IT",
        matches: "5",
        prompt: "", // Android only
        showPopup: true, // Android only
        showPartial: false
      }
    };
  },
  watch: {
    search(val, oldVal) {
      if (val !== oldVal && val && val.length > 3) {
        this.fetchSuggestion();
      }
    }
  },
  computed: {
    ...mapGetters({
      commonSearch: "wordsSearched/commonSearch"
    }),
    getItems() {
      let result = this.search ? this.items : this.commonSearch;
      // result.unshift({
      //   type: "FastSearch",
      //   name: "Spesa veloce"
      // });
      return result;
    }
  },
  directives: {
    "long-press": LongPress
  },
  methods: {
    ...mapActions({
      saveWord: "wordsSearched/saveWord",
      deleteWord: "wordsSearched/deleteWord"
    }),
    getImage(item) {
      return "/img_layout/categories_icon/" + item.id + ".svg";
    },
    onFocus() {
      this.isFocused = true;
    },
    onBlur() {
      this.isFocused = false;
    },
    fetchSuggestion: debounce(async function() {
      let _this = this;
      this.loading = true;
      if (this.search) {
        let newItems = [];
        await ProductService.suggest(this.search.trim()).then(function(
          response
        ) {
          let prevType = "none";

          forEach(response, function(value) {
            if (value.type != prevType) {
              newItems.push({ header: value.type });
              prevType = value.type;
            }
            newItems.push(value);
          });
        });
        cmService
          .search(
            this.search.trim(),
            ["post", "produttore", "recipe", "we_love_trees"],
            1,
            5
          )
          .then(function(response) {
            if (response.data.length > 0) {
              newItems.push({ header: "News e ricette" });
              forEach(response.data, function(value) {
                newItems.push({
                  name: value.title,
                  type: "News e ricette",
                  ...value
                });
              });
              newItems.push({
                type: "link",
                name: "Cerca tra news e ricette >"
              });
            }
          });
        _this.loading = false;
        _this.items = newItems;
      }
    }, 200),
    doSearch(item) {
      this.items = [];
      console.log(item);
      var currentQuery = {};
      if (item.type === "Suggerimenti") {
        //ho selezionato un nome
        currentQuery.q = item.name;
      } else if (item.type === "Categorie") {
        currentQuery.parent_category_id = item.id;
        currentQuery.filter = item.name;
        currentQuery.q = this.search;
      } else if (item.type === "Ricerche") {
        currentQuery.q = item.name;
      } else if (item.type === "Marche") {
        currentQuery.parent_vendor_id = item.id;
        currentQuery.filter = item.name;
        currentQuery.q = this.search;
      } else if (item.type === "Barcode") {
        currentQuery.barcode = item.name;
      } else if (item.type === "FastSearch") {
        currentQuery.q = "";
      } else if (item.type === "News e ricette") {
        currentQuery.q = this.search;
      } else if (item.type === "link") {
        currentQuery.q = this.search;
      } else if (this.search != null) {
        currentQuery.q = this.search;
        this.saveWord(currentQuery.q);
      }

      if (currentQuery.q || currentQuery.barcode) {
        AnalyticsService.search(this.search);
        //this.$store.dispatch("category/resetFilters");
        global.EventBus.$emit("resetFilters");
        if (item.type == "News e ricette") {
          let routeName;
          switch (item.subtype) {
            case "post":
              routeName = "NewsDetail";
              break;
            case "produttore":
              routeName = "ProducerDetail";
              break;
            case "recipe":
              routeName = "RecipeDetail";
              break;
            case "we-love-trees":
              routeName = "RecipeDetail";
              break;
            default:
              routeName = "Page";
          }
          this.$router.push({
            name: routeName,
            params: { postName: item.slug }
          });
        } else if (item.type == "link") {
          this.$router.push({
            name: "WpSearch",
            query: currentQuery
          });
        } else {
          this.$router.push({
            path: "/search",
            name: "Search",
            query: currentQuery
          });
        }

        this.$nextTick(() => {
          this.$refs.autocomplete.blur();
          this.search = currentQuery.q;
        });
      }
    },
    openFastSearchDialog() {
      this.$emit("closeSearchModal", true);
      var defaultConfig = {
        toolbarColor: "secondary",
        fullscreen: this.$vuetify.breakpoint.smAndDown
      };
      let properties = {
        waitForResult: true,
        width: 650
      };
      var config = Object.assign({}, properties, defaultConfig);
      this.dialogInstance = this.$dialog.show(FastSearchMenu, config);
    }
  },
  mounted() {
    global.EventBus.$on("clearSearch", () => {
      this.search = null;
      this.selected = null;
    });
  },
  beforeDestroy() {
    global.EventBus.$off("clearSearch");
  }
};
</script>
